/**
 * Documents & Factsheets Form Component
 * =====================================
 * Most component script can be found in file:
 *
 * -- `form/common/scripts/multiselect.js`
 * -- `form/common/scripts/fundfield.js`
 *
 * These files are shared by components:
 *
 * 1). Price & Performance
 * 2). Documents & Factsheets
 * 3). Dividends (Fund distribution)
 *
 */

// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { nodeListArray } from "../../../../global/js/utils/dom";
import { isElement } from "../../../../global/js/utils/element";
import Panel from "../../../../global/js/utils/panel";

const FORM = document.querySelector("form.documentsfactsheets-form");

const SELECTORS = {
    ACCORDION: "data-accordion-target",
    TABLE_PARENT_DIV: ".documents-list.list-reset",
    TABLE_PARENT_DIV_HIDDEN: ".documents-list.list-reset[hidden]",
    TABLE_HEADING_DIV: ".document-item--heading",
    IRISH_VCC_HEADING: "First Sentier Investors Global Umbrella Fund (Irish VCC/offshore)",
    SDR_DOCUMENT_TYPE: "sdr-client-facing-disclosure",
    DOC_TYPE_FILTER: "doctype-field",
    MESSAGE: ".message-noresults",
    ROWCOUNT: "data-rowcount"
};

const ELEMENTS = {};
let multiSelectorData = {};

function showNoResultsMessage() {
    ELEMENTS.LIST.forEach((list) => {
        const ROWS_MAX = Number(list.getAttribute(SELECTORS.ROWCOUNT) || 0);
        const ROWS_HIDDEN = list.querySelectorAll(".document-item[hidden]").length;
        const RESULT = ROWS_MAX > ROWS_HIDDEN;

        const accordionContent = list.closest(`[${SELECTORS.ACCORDION}]`);

        if (isElement(accordionContent)) {
            const message = accordionContent.querySelector(SELECTORS.MESSAGE) || {};
            message.hidden = RESULT;
            list.hidden = !RESULT;
        }
    });
}

function getDropdownValue() {
    const dropdown = document.getElementById(SELECTORS.DOC_TYPE_FILTER);
    return dropdown.value;
}

function hideNoResultHeading() {
    const docTypeValue = getDropdownValue();

    ELEMENTS.LIST.forEach((list) => {
        const HEADINGS = list.querySelectorAll(SELECTORS.TABLE_HEADING_DIV);

        HEADINGS.forEach((heading) => {
            if (heading.textContent === SELECTORS.IRISH_VCC_HEADING && docTypeValue === SELECTORS.SDR_DOCUMENT_TYPE) {
                heading.hidden = true;
            }
            else {
                heading.hidden = false;
            }
        });
    });
}

function hideNoResultAccordions() {
    const { strategy, fund } = multiSelectorData;

    ELEMENTS.ACCORDIONS = nodeListArray(FORM.querySelectorAll(`[${SELECTORS.ACCORDION}]`));
    ELEMENTS.ACCORDIONS.forEach((accordionContent) => {
        const TOTAL_TABLES = accordionContent.querySelectorAll(SELECTORS.TABLE_PARENT_DIV).length;
        const TABLES_HIDDEN = accordionContent.querySelectorAll(SELECTORS.TABLE_PARENT_DIV_HIDDEN).length;
        const RESULT = TOTAL_TABLES != TABLES_HIDDEN;

        accordionContent.parentElement.hidden = !RESULT;
        if (strategy === undefined && fund === undefined) {
            Panel.close(accordionContent, {
                immediate: true
            });
        } else if (RESULT) {
            Panel.open(accordionContent, {
                immediate: true
            });
        }
    });
}

export default (function documentsFactsheetsForm() {
    if (!isElement(FORM)) {
        return;
    }

    ELEMENTS.LIST = nodeListArray(FORM.querySelectorAll(`.documents-list[${SELECTORS.ROWCOUNT}]`));
    window.addEventListener(
        EVENTS.MULTISELECTOR.OPTION_CHANGED,
        (e) => {
            const { data } = e.detail;
            multiSelectorData = data;
            showNoResultsMessage();
            hideNoResultAccordions(data);
            hideNoResultHeading();
        },
        false
    );
})();
