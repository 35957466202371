// Constants
import EVENTS from "../../../../global/js/constants/events";

// APIs
import CookiesTerms from "../../../../global/js/api/cookiesSiteTerms";

// Utils
import { isAuthor } from "../../../../global/js/utils/aem";
import { nodeListArray } from "../../../../global/js/utils/dom";
import { isElement } from "../../../../global/js/utils/element";
import Template from "../../../../global/js/utils/template";
import { checkAndRedirectToVanityUrl, getRedirectToPath } from "../../../../global/js/utils/gateRedirect";

const countrySelector = document.querySelector("#country-selector");
const siteDisclaimer = document.querySelector("#site-disclaimer");
const { isVanityUrl, isVanityGeoTargeted } = window.FSSA.vanityDetails || {};
const countrySelectorLayout = document.querySelector(".selector-layout");
let isPublish;

const DATA = {};
const ELEMENTS = {};
const SETTINGS = {};

function getInvestorTypes(language) {
    const { languages = [] } = DATA.COUNTRY;
    const result = languages.find((lang) => lang.language === language) || {};
    return result.investorTypes || [];
}

function getInvestorType(type) {
    return getInvestorTypes(DATA.CODES.language).find((investorType) => investorType.investorType === type) || {};
}

function updateElements() {
    ELEMENTS.FORM = siteDisclaimer.querySelector("form.disclaimer-form");
    ELEMENTS.FORM_SUBMIT = ELEMENTS.FORM.querySelector("button[type=submit]");
    ELEMENTS.FORM_GLOBAL_LINK = ELEMENTS.FORM.querySelector("a.global-link");

    ELEMENTS.INVESTOR_LIST = ELEMENTS.FORM.querySelector("ul.investor-types");
    ELEMENTS.INVESTOR_PROMPT = ELEMENTS.FORM.querySelector("#investor-prompt");
    ELEMENTS.TERMS = ELEMENTS.FORM.querySelector("[data-terms]");

    updateSettings(ELEMENTS.FORM);
}

function updateSettings(form) {
    SETTINGS.ENDPOINT = form.getAttribute("data-endpoint");
    SETTINGS.COOKIE_EXPIRY = (() => {
        const DAY_SECONDS = 86400;
        return Number(form.getAttribute("data-expiry")) * DAY_SECONDS;
    })();
    SETTINGS.MESSAGES = {
        ERROR: form.getAttribute("data-msg-error"),
        EMPTY: form.getAttribute("data-msg-empty")
    };
}

function setInvestorItemHtml(investorTypeData, index) {
    const { investorType, name } = investorTypeData;
    return Template("#template-disclaimerInvestorType", {
        INVESTOR_TYPE_NAME: name,
        RADIO_ID: index,
        RADIO_VALUE: investorType
    });
}

function setInvestorTypeList(investorTypes) {
    const investorItems = investorTypes.map((investorType, index) => setInvestorItemHtml(investorType, index)).join("");
    ELEMENTS.INVESTOR_LIST.innerHTML = investorItems;
    ELEMENTS.FORM_SUBMIT.disabled = true;
}

async function getSiteTerms(localeData = {}) {
    const response = await fetch(`${SETTINGS.ENDPOINT}?${new URLSearchParams(localeData).toString()}`);
    if (!response.ok) {
        throw new Error(`HTTP error status: ${response.status}`);
    }
    return await response.text();
}

function investorListListener() {
    if (!isElement(ELEMENTS.INVESTOR_LIST)) {
        return;
    }

    ELEMENTS.INVESTOR_LIST.addEventListener(
        "change",
        (e) => {
            e.preventDefault();
            const { target } = e;
            const { country, language } = DATA.CODES;
            const { investorType, path } = getInvestorType(target.value);

            ELEMENTS.FORM.setAttribute("action", path || "#");
            ELEMENTS.TERMS.innerHTML = "";
            DATA.CODES.investorType = investorType; // Append investorType code

            getSiteTerms({
                country: country,
                language: language,
                audience: investorType
            })
                .then((text) => {
                    if (!text) {
                        ELEMENTS.TERMS.innerHTML = `<p>${SETTINGS.MESSAGES.EMPTY}</p>`;
                        ELEMENTS.FORM_SUBMIT.disabled = true;
                        return;
                    }

                    ELEMENTS.TERMS.innerHTML = text;
                    ELEMENTS.FORM_SUBMIT.disabled = false;
                })
                .catch((error) => {
                    console.warn(`Fetch operation error: ${error.message}`);
                    ELEMENTS.TERMS.innerHTML = `<p>${SETTINGS.MESSAGES.ERROR}</p>`;
                    ELEMENTS.FORM_SUBMIT.disabled = true;
                });
        },
        false
    );
}

function preselectRadioBtn(value) {
    const radios = nodeListArray(ELEMENTS.INVESTOR_LIST.querySelectorAll("input[type=radio]"));
    const radio = (() => {
        if (value) {
            return ELEMENTS.INVESTOR_LIST.querySelector(`input[type=radio][value="${value}"]`);
        } else if (radios.length === 1) {
            return radios[0];
        } else if (radios.length > 1) {
            // Prompt the visitor to make an investor type selection
            ELEMENTS.INVESTOR_PROMPT.hidden = false;
            ELEMENTS.TERMS.append(ELEMENTS.INVESTOR_PROMPT);
        }
    })();

    if (!isElement(radio)) {
        return;
    }

    radio.click();
}

function redirectToSite(destination) {
	let currentLocationPathName = window.FSSA.currentPagePath + ".html";
	if (isPublish === "true") {
        currentLocationPathName = currentLocationPathName.replace("/content/fssainvestors", "");
    }
	let urlParams = new URLSearchParams(location.search);
	let LOCALE_PATHNAME = currentLocationPathName.replace("/content/fssainvestors", "").split("/").slice(0, 4).join("/");

	if (urlParams.has("show_no_article_message")) {
		urlParams.delete("show_no_article_message");
	}

	if (urlParams.has("show_no_vanity_message")) {
        urlParams.delete("show_no_vanity_message");
    }

	// Check if requested location is the one we're already on - no need to redirect unnecessarily
	// Just close any Modal, and exit.
	if (LOCALE_PATHNAME !== "/" && destination.includes(LOCALE_PATHNAME)) {
		window.FSSA.elementStore.regionModal.close();
		return;
	}

	let currentAudiencePath = LOCALE_PATHNAME.substring(0, LOCALE_PATHNAME.lastIndexOf('.')) || LOCALE_PATHNAME;
	if (currentAudiencePath && !currentAudiencePath.startsWith('/')) {
		currentAudiencePath = "/" + currentAudiencePath;
	}
	let changedAudiencePath = destination.replace("/content/fssainvestors", "");
	if (changedAudiencePath) {
		changedAudiencePath = changedAudiencePath.substring(0, changedAudiencePath.lastIndexOf('.')) || changedAudiencePath;
	}
	let newDestination = currentLocationPathName.replace(currentAudiencePath, changedAudiencePath);
	const locationPathDepth = currentLocationPathName.replace("/content/fssainvestors", "").split("/").length;

    let pageType = "";
    const templateNameMetaTag = document.querySelector('meta[name="template"]');
    if (templateNameMetaTag !== null && templateNameMetaTag !== undefined) {
        pageType = templateNameMetaTag.content;
    }
	if (LOCALE_PATHNAME !== "/" && !LOCALE_PATHNAME.includes("global/en/") && urlParams.has("utm_source")) {
		if (locationPathDepth > 4) {
			checkAndRedirectToEqPage(newDestination, destination, urlParams, pageType, changedAudiencePath);
		} else {
		    window.location = getRedirectToPath(newDestination, urlParams, true);
		}
	} else {
		if (LOCALE_PATHNAME !== "/" && !LOCALE_PATHNAME.includes("global/en/") && locationPathDepth > 4) {
			checkAndRedirectToEqPage(newDestination, destination, urlParams, pageType, changedAudiencePath);
		} else {
			if (window.teaserTileRelPath) {
				newDestination = destination.replace(".html", window.teaserTileRelPath);
				checkAndRedirectToEqPage(newDestination, destination, urlParams, pageType, changedAudiencePath);
			} else if (isVanityUrl && isVanityGeoTargeted && !location.pathname.includes(".html")) {
			    checkAndRedirectToVanityUrl(`${changedAudiencePath}${location.pathname}`, destination, urlParams);
            } else {
			    window.location = getRedirectToPath(destination, urlParams, false);
			}
		}
	}
}

function checkAndRedirectToEqPage(newDestination, destination, urlParams, pageType, changedAudiencePath) {
	getPageStatus(newDestination, pageType, changedAudiencePath, urlParams.has("utm_source"))
		.then((pageStatus) => {
			if (pageStatus.pathExists) {
			    if (urlParams.has("utm_source")) {
                    if (pageType.includes("article")) {
                        var equivalentInsightPagePath = pageStatus.equivalentInsightPagePath;
                        if (equivalentInsightPagePath) {
                            newDestination = equivalentInsightPagePath;
                        }
                    }
                }
			    window.location = getRedirectToPath(newDestination, urlParams, true);
			} else {
				if (urlParams.has("utm_source")) {
					if (pageType.includes("article")) {
					    if(pageStatus.insightsPagePath){
					        destination = pageStatus.insightsPagePath;
					    }
                        urlParams.set('show_no_article_message', true);
                    } else {
					    destination = newDestination;
					}
				}
				window.location = getRedirectToPath(destination, urlParams, false);
			}
		})
		.catch((error) => {
			console.warn(`Fetch operation error: ${error.message}`);
		});
}

async function getPageStatus(newDestination, pageType, changedAudiencePath, urlContainsParamUtmSource) {
	const pageStatusEndpoint = "/bin/fsi/pageStatus?path={newRedirectPath}&website=fssa&audience=" + changedAudiencePath + "&pageType=" + pageType + "&urlContainsParamUtmSource=" + urlContainsParamUtmSource;
	const response = await fetch(pageStatusEndpoint.replace("{newRedirectPath}", newDestination));
	if (!response.ok) {
		throw new Error(`HTTP error status: ${response.status}`);
	}
	return await response.json();
}

function setTermsCookie() {
    // Drop chosen locale/audience terms cookie...
    // @note: calling this, expires any other FSSA site terms cookies
    // - cf. cookiesSiteTerms.js for more information
    return CookiesTerms.set(DATA.CODES, {
        expiry: SETTINGS.COOKIE_EXPIRY,
        pathname: ELEMENTS.FORM.getAttribute("action")
    });
}

function formSubmitEvent() {
    const formAction = (e) => {
        e.preventDefault();
        setTermsCookie();
        redirectToSite(ELEMENTS.FORM.getAttribute("action"));
    };

    ELEMENTS.FORM.hidden = false;
    ELEMENTS.FORM.addEventListener("submit", formAction, false);
    ELEMENTS.FORM_SUBMIT.addEventListener("click", formAction, false);
    // FORM_SUBMIT button is injected into modal footer area (outside the form-proper).
    // The addition of FORM_SUBMIT button click event is necessary to patch the
    // form submit functionality gap created by having to do this DOM manipulation.
}

function globalSiteLinkEvent() {
    ELEMENTS.FORM_GLOBAL_LINK.addEventListener(
        "click",
        (e) => {
            e.preventDefault();
            CookiesTerms.expire({ expireAll: true });
            // ^^ Important to expire ALL terms cookies to avoid visitor being pushed
            // into infinite loop of global site redirecting back to cookie `pagePath`.
            window.location = ELEMENTS.FORM_GLOBAL_LINK.getAttribute("href");
        },
        false
    );
}

export default (function initSiteDisclaimer() {
    if (isAuthor() || !isElement(countrySelector) || !isElement(siteDisclaimer)) {
        return;
    }

    if(isElement(countrySelectorLayout)){
        isPublish = countrySelectorLayout.dataset.publishView;
    }

    let firstRun = true;

    updateElements();
    investorListListener();
    formSubmitEvent();
    globalSiteLinkEvent();

    window.addEventListener(
        EVENTS.COUNTRY_SELECTOR.LOCALE_CHANGED,
        (e) => {
            const { codes, country } = e.detail;
            DATA.COUNTRY = country;
            DATA.CODES = codes;

            ELEMENTS.TERMS.innerHTML = "";
            setInvestorTypeList(getInvestorTypes(DATA.CODES.language));
            preselectRadioBtn(DATA.CODES.investorType);

            if (firstRun) {
                firstRun = false;
                siteDisclaimer.remove();
                window.FSSA.elementStore.siteDisclaimer = siteDisclaimer;
            }
        },
        false
    );
})();
